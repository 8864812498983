import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { useRouter } from 'next/router';

const SignUpForUpdatesForm = ({ show, handleClose }) => {

    const router = useRouter();

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [zip, setZip] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();


        const form_body = {
            "email": email,
            "pageUri": "https://hfschoolbox.com",
            "pageName": "SchooBox - Home",
            "firstname": firstname,
            "lastname": lastname,
            "zip": zip
        }

        const response = fetch('/api/email/form-submission', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form_body),
        });

        router.push('/thank-you');

    }

    return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Body>
        <div id='coming-soon' className='mfp-hide'>
            <div className='row'>
                <div className="col-12 text-center">
                    <h2>Coming Soon 2022</h2>
                    <p>Fill out the form below and we&apos;ll be in touch when we launch soon!</p>
                </div>
            </div>
            <form onSubmit={handleSubmit} className='aos-item' data-aos='fade-in'>
                <div className="col col-12 labelToggle">
                    <label htmlFor='first_name' className='sr-only'>First Name*</label>
                    <input type='text' onChange={(e) => setFirstname(e.target.value)} id='first_name' className='formField' name='firstname' placeholder="First Name*" required />
                </div>
                <div className="col col-12 labelToggle">
                    <label htmlFor='last_name' className='sr-only'>Last Name*</label>
                    <input type='text' onChange={(e) => setLastname(e.target.value)} id='last_name' className='formField' name='lastname' placeholder="Last Name*" required />
                </div>
                <div className="col col-12 labelToggle">
                    <label htmlFor='email_signup' className='sr-only'>Email Address*</label>
                    <input type='email'  onChange={(e) => setEmail(e.target.value)} id='email_signup' name='email' placeholder="Email Address*" required className="formField labelToggle" />
                </div>
                <div className="col col-12 labelToggle">
                    <label htmlFor='zip_signup' className='sr-only'>Zip Code*</label>
                    <input type='text' onChange={(e) => setZip(e.target.value)} id='zip_signup' name='zip' placeholder='Zip Code*' required className="formField labelToggle" />
                </div>
                <div className="col col-12 text-center">
                    <button variant='primary' type='submit' className='button'>I&apos;m In!</button>
                </div>
            </form>
        </div>
        </Modal.Body>
    
    </Modal>
    );
};

export default SignUpForUpdatesForm;

SignUpForUpdatesForm.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    reloadSchool: PropTypes.func,
};

SignUpForUpdatesForm.defaultProps = {
    show: false,
    handleClose: () => {},
};
